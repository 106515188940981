
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import Finance_bannerImg from "../../assets/images/finance_banner.jpeg";
import TreadingarrowDown from "../../assets/images/treading-arrow-down.png"
import TreadingarrowUp from '../../assets/images/treading-arrow-up.png';

function  toMillion(value)
{
    if(value<0){
        
        return Math.abs(Number(value)) >= 1.0e9
        ? "$"+-(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? "$"+-(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? "$"+-(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
        : "$"+-(Math.abs(Number(value))/ 1.0e6).toFixed(2)+"M";
    }else{
        return Math.abs(Number(value)) >= 1.0e9
        ? "$"+(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? "$"+(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? "$"+(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"
        : "$"+(Math.abs(Number(value))/ 1.0e6).toFixed(2)+"M";
    }
}  
export default function FinancialInformation() {

  useEffect(() => {

  }, []);

  const FinInfo1 = {
    // tooltip: {
    //   trigger: 'item',
    //   backgroundColor: 'rgba(50,50,50,0.7)',
    //   borderColor: "#333",
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
      // axisPointer: {
      //   type: 'shadow'
      // }

    // },
    tooltip: {
      trigger: "item",
      formatter: function(params) {
        return (
          params.name + " : " + params.percent.toFixed(2) + "%"
        );
      },
      axisPointer: {
        type: 'shadow'
      },
      confine: true
    },
    legend: {
      itemGap: 4,
      orient: 'vertical',
      bottom: "-3%",
      left: "0%",
      itemWidth: 6,
      itemHeight: 6,
      textStyle: {
        color: "#fff",
        fontSize: '9',
        fontStyle: 'normal',
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: '75%',
        bottom:'12%',
        center: ['50%', '40%'],

        // top: '0%',
        // left: '0%',
        // right: '0%',
        // bottom: '15%',
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'left',
          formatter: '{d}%',
          position: 'inside',
          textStyle: {
            fontSize: '8',
            color: "#fff"
          }
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 10,
        //     fontWeight: 'bold'
        //   }
        // },
        labelLine: {
          show: false
        },
        data: [
          {
            value: 1424201556, name: 'Property Taxes for general purpose',
            itemStyle:
            {
              color: '#2b79b3'
            }

          },

          {
            value: 207645552, name: 'Operating Grants',
            itemStyle:
            {
              color: '#2b3d82'
            }

          },
          {
            value: 169823805, name: 'Property Taxes for Debt Purposes',
            itemStyle:
            {
              color: '#6f2e66'
            }
          },


          {
            value: 22826145, name: 'Extra Curricular Activites',
            itemStyle:
            {
              color: '#359760'
            }
          },

          {
            value: 27130323, name: 'Others',
            itemStyle:
            {
              color: '#2C7AB8'
            }
          },

          // {
          //   value: 8188337, name: 'Others',
          //   itemStyle:
          //   {
          //     color: '#402B50'
          //   }
          // },

        ]
      }
    ]
  };

  const FinInfo2 = {
    // tooltip: {
    //   trigger: 'item',
    //   backgroundColor: 'rgba(50,50,50,0.7)',
    //   borderColor: "#333",
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
    //   axisPointer: {
    //     type: 'shadow'
    //   }

    // },
    tooltip: {
      trigger: "item",
      formatter: function(params) {
        return (
          params.name + " : " + params.percent.toFixed(2) + "%"
        );
      },
      axisPointer: {
        type: 'shadow'
      },
      confine: true
    },
    legend: {
      itemGap: 4,
      orient: 'vertical',
      bottom: "-3%",
      left: "0%",
      itemWidth: 6,
      itemHeight: 6,
      textStyle: {
        color: "#fff",
        fontSize: '9',
        fontStyle: 'normal'
      }
    },


    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: '75%',
        bottom:'12%',
        center: ['50%', '40%'],
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'left',
          formatter: '{d}%',
          position: 'inside',
          textStyle: {
            fontSize: '8',
            color: "#fff"
          }
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 10,
        //     fontWeight: 'bold'
        //   }
        // },
        labelLine: {
          show: false
        },
        data: [
          {
            value: 762800496, name: 'Contracted instructional services between schools',
            itemStyle:
            {
              color: '#2b79b3'
            }

          },

          {
            value: 493109617, name: 'Instruction',
            itemStyle:
            {
              color: '#2b3d82'
            }

          },
          {
            value: 102547790, name: 'Plant, maintenance and operations',
            itemStyle:
            {
              color: '#6f2e66'
            }
          },


          {
            value: 60846469, name: 'School leadership',
            itemStyle:
            {
              color: '#359760'
            }
          },

          {
            value: 386944157, name: 'Others',
            itemStyle:
            {
              color: '#2C7AB8'
            }
          },

          // {
          //   value: 332743744, name: 'Others',
          //   itemStyle:
          //   {
          //     color: '#402B50'
          //   }
          // },

        ]
      }
    ]
  };

  const RevenueExpense = {
    title: {
      text: 'Revenue and Expense over the year',
      left: 10,
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',
      },
    },
    legend: {
      data: ['Revenue', 'Expense'],

      right: 0,
      right: "60%",
      // bottom:"10%",
      itemWidth: 6,
      itemHeight: 6,
      top: '24%',
      textStyle: {
        color: "#fff",
        fontSize: '11',
        fontStyle: 'normal',
      },
    },


    tooltip: {

      backgroundColor: 'rgba(50,50,50,0.7)',
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',
      },
      trigger: 'axis',
      // formatter: function(params) {
      //   return (
      //     params.name + " : " + toMillion(params.value)
      //   );
      // },
      axisPointer: {
        type: 'line',
        lineStyle: {
          type: "line"
        }
      },


    },
    grid: {
      tooltip: {
        padding: [50, 100, 100, 100],
        color: '#fff'
      }
    },

    // grid: {

    //   right: '0%',
    //   bottom: '0%',
    //   top: '0%',
    //   width: '150%',
    //   height: '120%',
    //   left: '-22%',
    //   containLabel: true
    // },

    // yAxis: [
    //  { type: 'value'}
    // ],
    // xAxis: {
    //   boundaryGap: false,
    //   type: 'category',
    //   data: ['2015', '2016', '2017', '2018'],
      
    // },
    // color: ['#359760','#2b3d82'],
    // series: [
    //   {
    //     data: [2000, 1000, 2000, 500],
    //     type: 'line',
    //     colorBy: "data",
    //     areaStyle: {},
    //     label: {
    //       show: true,
    //       position: 'top',
    //       textShadowBlur: 0,
    //       color: '#fff'
    //     },
    //   },
    //   {
    //     data: [1000, 500, 2000, 3000],
    //     type: 'line',
    //     colorBy: "data",
    //     areaStyle: {},
    //     label: {
    //       show: true,
    //       position: 'top',
    //       textShadowBlur: 0,
    //       color: '#fff'
    //     },
    //   },
    // ]
    grid: {
      left: '-5%',
      right: '-10%',
      bottom: '0%',
      width:'110%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['2019','2020', '2021', '2022'],
        splitLine: { show: false },
        axisLabel: {
          inside: true,
          color: '#fff'
        },
        z:10
      }
    ],
    yAxis: [
      {
        type: 'value', splitLine: { show: false }, axisLabel: {
         show:false
        }
        
      }
    ],
    color: ['#2b3d82','#359760'],
    series: [
     
      
      {
        name: 'Revenue',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        label: {
          show: false,
          position: 'top',
          color:'#fff'
        },
        // emphasis: {
        //   focus: 'series'
        // },
        data: [1777657207, 1757890245, 1775213465, 1851600000]
      },
      {
        name: 'Expense',
        type: 'line',
        stack: 'Total',
        label: {
          show: false,
          position: 'top',
          color:'#fff'
        },
        areaStyle: {},
        // emphasis: {
        //   focus: 'series'
        // },
        data: [1706443552, 1672957566, 1800313940, 1806200000]
      }
    ]
  };

  return (
    <div className="bg-[#081630] text-[#fff]">
      <div className="relative h-[190px]">
        <div className=" h-[180px] ">
          <img src={Finance_bannerImg} className="h-[190px] w-full " />
        </div>
        <div className="w-full absolute bottom-0 h-[40px] bg-[#1d192f9c] py-2 px-3">
          <h3 className="text-[16px] text-[#fff]">Financial Information</h3>
        </div>

      </div>

      <div className="grid grid-cols-2 gap-10 px-5 mt-5">
        <div className="flex items-start gap-3">

          <div className="pt-2">
            <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-1">
              Revenue</p>
            <div className="flex items-center gap-5">
              <h4 className="text-[13px] text-[#fff]">$1851.6 M</h4>
              <div className='h-4 trendingdown'>
                <img src={TreadingarrowDown} className="" /> -0.1%</div>
            </div>
          </div>
        </div>


        <div className="flex items-start gap-3">

          <div className="pt-2">
            <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-1">
              Expense</p>

            <div className="flex items-center gap-5">
              <h4 className="text-[13px] text-[#fff]">$1806.2 M</h4>
              <div className='h-4 trendingup'>
                <img src={TreadingarrowUp} className="" /> 0.2%</div>
            </div>

          </div>


        </div>
      </div>

      <div className="grid grid-cols-2 gap-10 px-5 mt-5 mb-2 border-t border-[#ffffff26]">
        <div className="FinInfo1">
          <ReactEcharts option={FinInfo1} />
        </div>
        <div className="FinInfo2">
          <ReactEcharts option={FinInfo2} />
        </div>
      </div>
      <div className="border-t border-[#ffffff26]">
      <div className="mb-0 RevenueExpense">
        <ReactEcharts option={RevenueExpense} />
      </div>
</div>



    </div>
  )
}
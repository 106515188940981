import React from 'react'
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap'
import "./index.css";
import LoginFrame from '../../assets/images/loginLogo.jpg';
import Loginlogo from '../../assets/brands/logo.png';
import googleLogo from '../../assets/images/googleLogo.png';
import { useState } from 'react';


  


const LogIn = (props) => { 
 

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    // Add code here to handle form submission (e.g. sending login credentials to server)
  }
  
  

  
  return (
    <>
      {/* <div style={{ minHeight:"100vh", overflow: 'hidden' ,display:"flex" }}> */}
        <div  className='loginLeft p-0 dashboard' style={{ width:"100vw",height:'100vh' }}>
          {/* <div className='LoginDet'> */}
            <img className='Loginlogo' src={Loginlogo} />
            <p className='loginName'>
                  LOGIN
                </p>
                <p className='loginDes'>Provide your credentials  to proceed...</p>
                <div className="logoSignIn my-4">               
                <button className="login-button-azure"  onClick={()=> {props.login()}}>
                  <img src={googleLogo} className="googleLogo is-mar-right-2 " />
                  <span className="sigin-text">Sign in with Google</span>                 
                </button>
               
                 
              </div>             
                
                <div className='loginForm' style={{ display: "flex", justifyContent: "center" }}>
                <form onSubmit={handleSubmit} style={{ maxWidth: "400px", width: "100%", padding: "20px", border: "1px dashed grey" }}>
                     
                      <div style={{ marginBottom: "20px" }}>
                        <label style={{ display: "block", marginBottom: "5px" ,color: "white"}}>Email:</label>
                        <input
                          required
                          type="email"
                          value={userEmail}
                          placeholder={"name@example.com"}
                          onChange={(event) => setUserEmail(event.target.value)}
                          style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                        />
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <label style={{ display: "block", marginBottom: "5px",color: "white" }}>Password:</label>
                        <input
                          required
                          type="password"
                          placeholder={"**************"}
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                        />
                      </div>
                      <button type="submit" style={{ backgroundColor: "#081630", color: "#fff", border: "none", borderRadius: "5px", padding: "10px 20px", cursor: "pointer" ,width:'100%'}}>Login</button>
                    </form>
                </div>
                <div>
                  <p className="signUp">
                    Not Registered Yet? <a href="#">  Create account.</a>
                  </p>
                </div>
          {/* </div> */}
        </div>
        {/* <div className='p-0 ' style={{ width:"50vw" }}>
          <img className='loginImg' src={LoginFrame} />
        </div> */}
      {/* </div> */}
    </>
  )
}
export default LogIn
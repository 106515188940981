import axios from 'axios';

const client = axios.create({
  // baseURL: 'http://localhost:80/'
  //UAT_NEW_URL
  baseURL: 'https://uat.aisd.executivedashboard.innive.com/'
  //dev URL
  // baseURL: 'https://snowflakeaustinapi-wckqd7o3eq-uw.a.run.app/'
  //UAT URL
  // baseURL: 'https://snowflakeaustinapiuat-wckqd7o3eq-uw.a.run.app/'
});

export default client;


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import ReactEcharts from "echarts-for-react";

import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';

import SchoolDistBannerImg from "../../assets/images/school_dist_banner.jpeg"
import InterviewImg from "../../assets/images/interview.png"

// import HorizontalStackBars from "../components/common/horizontalstackbars";
import HorizontalStackBars from "../../components/common/horizontalstackbars/horizontalstackbar";




export default function StudentCharacteristics() {

    useEffect(() => {


    }, []);
    const studentteacherratio = {
        yaxis: ["student"],
        value: [13],
        value1: [1]
    }

    //     const SchoolDistrictEmployees = {

    //         tooltip: {
    //             trigger: 'axis',
    //             backgroundColor: 'rgba(50,50,50,0.7)',
    //             borderColor: "#333",
    //             textStyle: {
    //                 fontSize: 12,
    //                 fontWeight: "normal",
    //                 color: '#fff',
    //             },
    //             axisPointer: {
    //                 type: 'shadow'
    //             }
    //         },
    //         grid: {
    //             left: '0',
    //             right: '0',
    //             top: '0',
    //             height: '100%',
    //             containLabel: true
    //         },
    //         xAxis: {
    //             show: false,
    //             type: 'value',
    //         },
    //         yAxis: [{
    //             type: 'category',
    //             data: ['A-Audlt/Higher Ed', 'C-Combined ', 'K12-Combined Elementary/Secondary', 'C-Combined Middle/High', 'E-Elementary', 'M-Middle', 'h-high'],
    //             axisLine: {
    //                 show: false,
    //             },
    //             axisTick: {
    //                 show: false,
    //             },

    //             axisLabel: {
    //                 inside: true,
    //                 color: '#fff',
    // padding:30
    //             },
    //             z: 10
    //         },
    //         {
    //             type: 'category',

    //             axisLine: {
    //                 show: false
    //             },
    //             axisTick: {
    //                 show: false
    //             },
    //             axisLabel: {
    //                 color: "#fff"
    //             },
    //         },

    //         ],
    //         series: [{
    //             type: 'bar',
    //             stack: 'total',
    //             barWidth: "25px",
    //             barGap: "20",
    //             itemStyle: {
    //                 normal: {
    //                     color: '#1f46ab',
    //                 }
    //             },
    //             label: {
    //                 show: true,
    //                 position: 'center',
    //                 color:'#fff',
    //                 padding:8
    //             },
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [80, 100, 100, 90, 90, 90, 90]
    //         },
    //         {
    //             type: 'bar',
    //             stack: 'total',
    //             barWidth: "25px",
    //             barGap: "20",
    //             itemStyle: {
    //                 normal: {
    //                     color: '#892c69',
    //                 }
    //             },
    //             avoidLabelOverlap: false,
    //             label: {
    //                 show: true,
    //                 position: 'center',
    //                 color:'#fff',
    //                 padding:8
    //             },
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [20, 0, 0, 10, 10, 10, 10]
    //         },

    //         ]
    //     };


    const SchoolDistrictEmployees = {

        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(50,50,50,0.7)',
            borderColor: "#333",
            textStyle: {
                fontSize: 12,
                fontWeight: "normal",
                color: '#fff',
            },
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '0',
            right: '0',
            top: '0',
            height: '100%',
            containLabel: true
        },
        xAxis: {
            show: false,
            type: 'value',
        },
        yAxis: [{
            type: 'category',
            data: ['Others(Early Childhood Centre,Etc)', 'High School', 'Middle School', 'Elementary'],
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },

            axisLabel: {
                inside: true,
                color: '#fff',
                padding: 16
            },
            z: 10
        },
        {
            type: 'category',

            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color: "#fff"
            },
        },

        ],
        series: [{
            type: 'bar',
            stack: 'total',
            barWidth: "25px",
            barGap: "20",
            itemStyle: {
                normal: {
                    color: '#1f46ab',
                }
            },
            label: {
                show: true,
                position: 'right',
                color: '#fff',
                // padding:6.5,
                // paddingLeft:40,

            },
            emphasis: {
                focus: 'series'
            },
            data: [43, 1329, 1088, 3031]
        },
            // {
            //     type: 'bar',
            //     stack: 'total',
            //     barWidth: "25px",
            //     barGap: "20",
            //     itemStyle: {
            //         normal: {
            //             color: '#892c69',
            //         }
            //     },
            //     avoidLabelOverlap: false,
            //     label: {
            //         show: true,
            //         position: 'center',
            //         color:'#fff',
            //         padding:8
            //     },
            //     emphasis: {
            //         focus: 'series'
            //     },
            //     data: [20, 0, 0, 10, 10, 10, 10]
            // },

        ]
    };

    const SchoolDistrictEmployeesnew = {
        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(50,50,50,0.7)',
            borderColor: "#333",
            textStyle: {
                fontSize: 12,
                fontWeight: "normal",
                color: '#fff',
            },
            axisPointer: {
                type: 'shadow'
            },
            confine: true
        },
        grid: {
            left: '6%',
            bottom: '0%',
            top: "0%",
            right: '10%',
            height: '100%',
            containLabel: true
        },

        yAxis: [
            {
                type: 'category',
                position: "left",
                inverse: true,
                data: ['Elementary', 'Middle School', 'High School', 'Others'],
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#E3E3E3'
                    }
                },
                axisLabel: {
                    color: '#fff',
                    fontSize: '10',
                    fontWeight: "normal",
                    position: 'insideLeft',
                },
                axisTick: {
                    show: false,
                },
            },
        ],

        xAxis: {
            show: false,
            inverse: false,
            min: 0,
            max: 3500,
            interval: 10,
        },

        series: [
            {
                label: {
                    normal: {
                        show: true,
                        position: "right",
                        fontSize: 10,
                        // padding: 10,
                        "formatter": function (params) {
                            return params.value.VALUE
                        },
                        "textStyle": {
                            "color": "#fff"
                        }
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: [3031, 1088, 1329, 43],
                type: 'bar',
                barWidth: "80%",
                color: '#1F46AB',
                itemStyle: {
                    normal: {
                        barBorderRadius: [3, 3, 3, 3],
                        color: '#1F46AB',
                    }
                }

            }
        ]
    };

    return (
        <div className="">

            <div className="bg-[#081630] text-[#fff] h-[]">
                <div className="relative h-[190px]">
                    <div className=" h-[180px] ">
                        <img src={SchoolDistBannerImg} className="h-[190px] w-full " />
                    </div>
                    <div className="w-full absolute bottom-0 h-[40px] bg-[#1d192f9c] py-2 px-3">
                        <h3 className="text-[16px] text-[#fff]">School District Employees</h3>
                    </div>

                </div>
                <div className="grid grid-cols-2 gap-10 px-5 mt-5">
                    <div className="flex items-start gap-3">
                        <img src={InterviewImg} className="h-[38px] w-[38px]" />
                        <div className="pt-2">
                            <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-1">Total Teachers</p>
                            <h4 className="text-[20px] text-[#fff]">5491</h4>
                        </div>
                    </div>

                    <div className="flex items-start gap-3">

                        <div className="pt-2">
                            <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-1">
                                Administrators &Professional Staff</p>
                            <h4 className="text-[20px] text-[#fff]">1569</h4>
                        </div>


                    </div>
                </div>
                <div className="border-t border-[#ffffff26]">
                    <div className="px-2 mt-5">

                        <div className="flex justify-between">
                            <p className="text-[9.5px] text-[#ebebeb] font-light  mb-1"> Teacher</p>
                            {/* <p className="text-[9.5px] text-[#ebebeb] font-light mb-1"> Administrators</p> */}
                        </div>
                    </div>

                </div>


                <div className="px-2 mt-1">
                    <ReactEcharts option={SchoolDistrictEmployeesnew} style={{ height: '210px', width: '100%' }} />
                </div>
                <div className="border-t border-[#ffffff26]">
                    <div className="grid grid-cols-2 gap-10 px-5 mt-2">
                        <div className="flex items-start gap-3">
                            <div className="">
                                <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-1">Student/ Teacher Ratio</p>
                                <h4 className="text-[20px] text-[#fff]">13 :1</h4>
                                {/* <HorizontalStackBars
                                    barWidth='16'
                                    data={studentteacherratio}
                                    names={["Student", "Teacher"]}
                                    grid={{
                                        "left": '0%',
                                        "top": '0%',
                                        "right": '0%',
                                        "containLabel": "true"
                                    }}
                                    /> */}
                            </div>
                        </div>
                        <div className="flex items-start gap-3">
                            <div className="">
                                <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-2">
                                    Student/ Counselor Ratio</p>
                                <h4 className="text-[20px] text-[#fff]">--</h4>
                                {/* <h4 className="text-[20px] text-[#fff]">377:1</h4> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Card } from 'primereact/card';
// import ReactEcharts from "echarts-for-react";
import { Dropdown } from 'primereact/dropdown';
import DrillDown from "../assets/images/drilldown.png";
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { ProductService } from '../service/ProductService';
import thumb from '../assets/images/strength.png';
import improvementimg from '../assets/images/improvement.png';
import caretup from "../assets/images/caret-up.png";
import caretdown from "../assets/images/caret-down.png";
import thumbdown from '../assets/images/weakness.png';
import TopNav from "../components/common/topnav.component";
import k12darklogo from '../assets/images/k12-dark-logo.png';
import ReactEcharts from "echarts-for-react";
import { useDispatch, useSelector } from "react-redux";
import {fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART,fetchImprovement_Tile_Report,fetchStrength_Tile_Report,fetchWeakness_Tile_Report
} from '../redux/slices/strengthweakness';
import TreeMapEcharts from '../components/tree-graph';

export default function StrengthWeakness(props) {
    const dispatch = useDispatch();
    const STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata = useSelector(state => state.strengthweakness.STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART);
    const Improvement_Tile_Reportdata = useSelector(state => state.strengthweakness.Improvement_Tile_Report);
    const Strength_Tile_Reportdata = useSelector(state => state.strengthweakness.Strength_Tile_Report);
    const Weakness_Tile_Reportdata = useSelector(state => state.strengthweakness.Weakness_Tile_Report);

    const STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTloading = useSelector(state => state.netsales.STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTloading);
    console.log("STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata",STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata)
    // console.log("Improvement_Tile_Reportdata",Improvement_Tile_Reportdata)
    // console.log("Strength_Tile_Reportdata",Strength_Tile_Reportdata)
    // console.log("Weakness_Tile_Reportdata",Weakness_Tile_Reportdata)
    const [selectedoptions, setSelectedoptions] = useState('school_level');
    const [selectedoptions1, setSelectedoptions1] = useState('student_ethnicity');
    const [selectedoptions2, setSelectedoptions2] = useState('student_grade');
    const [selectedoptions3, setSelectedoptions3] = useState('student_gender');
    const [nestedFields, setNestedFields] = useState(["DIMENSION_1","DIMENSION_2","DIMENSION_3","DIMENSION_4"]);
    const [valueFields, setValueFields] = useState(["ACTUAL_D1","ACTUAL_D2","ACTUAL_D3","ACTUAL_D4"]);

    sessionStorage.setItem("selectedoptions", selectedoptions);
    sessionStorage.setItem("selectedoptions1", selectedoptions1);
    sessionStorage.setItem("selectedoptions2", selectedoptions2);
    sessionStorage.setItem("selectedoptions3", selectedoptions3);


    const options = [
        { name: 'School Type', value: 'school_level' },
        { name: 'Grade Level', value: 'student_grade' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'Homeless', value: 'student_homeless' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Special Ability', value: 'student_special_ability' },
        { name: 'ELL', value: 'student_ell' },
        { name: 'Language Classification', value: 'student_language_classification' }
    ];

    const options1 = [
        { name: 'School Type', value: 'school_level' },
        { name: 'Grade Level', value: 'student_grade' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'Homeless', value: 'student_homeless' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Special Ability', value: 'student_special_ability' },
        { name: 'ELL', value: 'student_ell' },
        { name: 'Language Classification', value: 'student_language_classification' }
    ];

    const options2 = [
        { name: 'School Type', value: 'school_level' },
        { name: 'Grade Level', value: 'student_grade' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'Homeless', value: 'student_homeless' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Special Ability', value: 'student_special_ability' },
        { name: 'ELL', value: 'student_ell' },
        { name: 'Language Classification', value: 'student_language_classification' }
    ];

    const options3 = [
        { name: 'School Type', value: 'school_level' },
        { name: 'Grade Level', value: 'student_grade' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'Homeless', value: 'student_homeless' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Special Ability', value: 'student_special_ability' },
        { name: 'ELL', value: 'student_ell' },
        { name: 'Language Classification', value: 'student_language_classification' }
    ];

    useEffect(() => {
        // dispatch(fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART({
        //   "elasticQueryName": "STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART",
        //   "filters": [
        //   ],
        //   "dynamicColumns": [],
        // }));

        dispatch(fetchImprovement_Tile_Report({
            "elasticQueryName": "Improvement_Tile_Report",
            "filters":[{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2022"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
          }));

          dispatch(fetchStrength_Tile_Report({
            "elasticQueryName": "Strength_Tile_Report",
            "filters":[{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2022"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
          }));

          dispatch(fetchWeakness_Tile_Report({
            "elasticQueryName": "Weakness_Tile_Report",
            "filters":[{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2022"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
          }));
    },[])


    const drilldown =  {
        series: [{
          type: "tree",
          data: [{
            name: "Actual 76.9%",
            symbolSize:10,
            itemStyle:{
               color: '#4C80E4',
          shadowColor: 'rgba(76, 128, 228, 1)',
          shadowBlur: 10,
          fill:true
          
            },
            children: [{
              name: "Middle(81.4%)",
                   lineStyle: {
            width: 20,
            color:"rgb(164, 202, 84)"
            },
              symbolSize: 10,
               label:{
              show:true,
              position:"left"
            },
              
            }, 
            // second chart 
            {
              name: "Elementrary(79.7%)",
             
                   lineStyle: {
            width: 12,
            color:"rgb(202, 84, 84)"
            },
               symbolSize: 10,
               
              }, {
              name: "Combined Middle/High(56.9%)",
                   lineStyle: {
            width: 20,
            color:"rgb(164, 202, 84)"
            },
              symbolSize: 10,
             
            }, {
              name: "Combined Elementry /Secondory(98.4%)",
                   lineStyle: {
            width: 20,
            color:"rgb(164, 202, 84)"
            },
              symbolSize: 10,
            }]
          }],
          label: {
            position: "left"
          }
        }]
}

    
    const handleTreeOnClick = (node) => {
        console.log(node);
    }

    const handleNestedFieldChange = (values) => {
        setNestedFields(values);
    }


    const [products, setProducts] = useState(Strength_Tile_Reportdata);
    const [products1, setProducts1] = useState(Improvement_Tile_Reportdata);
    const [products2, setProducts2] = useState(Weakness_Tile_Reportdata);

    useEffect(() => {
         setProducts(Strength_Tile_Reportdata);
         setProducts1(Improvement_Tile_Reportdata);
         setProducts2(Weakness_Tile_Reportdata);
    }, [products,products1,products2]);

    const [selectedmetric, setSelectedmetric] = useState("10083");
    sessionStorage.setItem("selectedmetric", selectedmetric);

    const [selectedmetricname, setSelectedmetricname] = useState("% of student above STAAR Reading benchmark");

    useEffect(() => {
        const handleBeforeUnload = () => {
          window.sessionStorage.removeItem('allfilter');
          window.sessionStorage.removeItem('selectedoptions');
          window.sessionStorage.removeItem('selectedoptions1');
          window.sessionStorage.removeItem('selectedoptions2');
          window.sessionStorage.removeItem('selectedoptions3');
          window.sessionStorage.removeItem('selectedmetric');
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);
        var defaultallfilter = JSON.parse(sessionStorage.getItem("allfilter"));

    useEffect(() => {
        // const body = {
        //     "elasticQueryName": "",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension_1}", columnValue: [selectedoptions] },
        //     { columnName: "#{dimension_2}", columnValue: [selectedoptions1] },
        //     { columnName: "#{dimension_3}", columnValue: [selectedoptions2] },
        //     { columnName: "#{dimension_4}", columnValue: [selectedoptions3] }],
        //     "pivotConfig": [],

        // };
        let body;
        if(defaultallfilter){
            body = {
                "elasticQueryName": "",
                "filters": defaultallfilter.filters.concat([{ "columnName": "metric_id", "columnValue": [selectedmetric] }]),
                "dynamicColumns": [{ columnName: "#{dimension_1}", columnValue: [selectedoptions] },
                { columnName: "#{dimension_2}", columnValue: [selectedoptions1] },
                { columnName: "#{dimension_3}", columnValue: [selectedoptions2] },
                { columnName: "#{dimension_4}", columnValue: [selectedoptions3] }],
            };
        }else{
            body = {
                "elasticQueryName": "",
                "filters": [{"columnName": "SCHOOL_YEAR","columnValue": ["2022"],"excludeKeyword": false},{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
                "dynamicColumns": [{ columnName: "#{dimension_1}", columnValue: [selectedoptions] },
                { columnName: "#{dimension_2}", columnValue: [selectedoptions1] },
                { columnName: "#{dimension_3}", columnValue: [selectedoptions2] },
                { columnName: "#{dimension_4}", columnValue: [selectedoptions3] }],
            };
        }
        handleperformance(body)
    }, [selectedoptions,selectedoptions1,selectedoptions2,selectedoptions3,selectedmetric]); 

    const handleperformance = (body) => {
        dispatch(fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART(body));
    }
    const handleTopTileFilterClick = (tile) => {
        setSelectedmetric(tile?.METRIC_ID)
        setSelectedmetricname(tile?.METRIC_NAME)
    }
    const strength = (Strength_Tile_Reportdata) => {
        return (
            <Strength_Tile_Report clicker={Strength_Tile_Reportdata} clickerFunc={handleTopTileFilterClick} METRIC_ID={Strength_Tile_Reportdata?.METRIC_ID} METRIC_NAME={Strength_Tile_Reportdata?.METRIC_NAME} ACTUAL={Strength_Tile_Reportdata?.ACTUAL} TARGET={Strength_Tile_Reportdata?.TARGET}
            TARGET_VARIANCE={Strength_Tile_Reportdata?.TARGET_VARIANCE} LAST_PERIOD={Strength_Tile_Reportdata?.LAST_PERIOD} LAST_PERIOD_VARIANCE={Strength_Tile_Reportdata?.LAST_PERIOD_VARIANCE}       
            />
        );
    };
    const improvement = (Improvement_Tile_Reportdata) => {
        return (
            <Strength_Tile_Report clicker={Improvement_Tile_Reportdata} clickerFunc={handleTopTileFilterClick} METRIC_ID={Improvement_Tile_Reportdata?.METRIC_ID} METRIC_NAME={Improvement_Tile_Reportdata?.METRIC_NAME} ACTUAL={Improvement_Tile_Reportdata?.ACTUAL} TARGET={Improvement_Tile_Reportdata?.TARGET}
            TARGET_VARIANCE={Improvement_Tile_Reportdata?.TARGET_VARIANCE} LAST_PERIOD={Improvement_Tile_Reportdata?.LAST_PERIOD} LAST_PERIOD_VARIANCE={Improvement_Tile_Reportdata?.LAST_PERIOD_VARIANCE}       
            />
        );
    };
    const weakness = (Weakness_Tile_Reportdata) => {
        return (
            <Strength_Tile_Report clicker={Weakness_Tile_Reportdata} clickerFunc={handleTopTileFilterClick} METRIC_ID={Weakness_Tile_Reportdata?.METRIC_ID} METRIC_NAME={Weakness_Tile_Reportdata?.METRIC_NAME} ACTUAL={Weakness_Tile_Reportdata?.ACTUAL} TARGET={Weakness_Tile_Reportdata?.TARGET}
            TARGET_VARIANCE={Weakness_Tile_Reportdata?.TARGET_VARIANCE} LAST_PERIOD={Weakness_Tile_Reportdata?.LAST_PERIOD} LAST_PERIOD_VARIANCE={Weakness_Tile_Reportdata?.LAST_PERIOD_VARIANCE}       
            />
        );
    };
    const Strength_Tile_Report = (props) => {   
        // const [selectedmetric, setSelectedmetric] = useState("10003");  
        return (
          <>
          <div id='cardtilesCustomer ' onClick={
            () => props.clickerFunc(props.clicker)
          }>
            {
                props?.METRIC_ID == selectedmetric?<div className=" text-center strength-vertical-slider">
                <div className="">
                <div className="strength-vertical-slider-active bg-[#27304a] w-full flex justify-between items-center px-2">
                    <div className='w-full flex justify-between'>
                        <div className=''><h4 className="mb-1 firstrowtext py-3 text-left" style = {{fontSize:"14px"}}>{props?.METRIC_NAME}</h4></div>
                        <div className=''><h4 className="mb-1 strenth-pers py-3 text-right">{props?.ACTUAL.toFixed(1)}%</h4></div>                    
                    </div>
                    <div className='w-full flex justify-between'>
                        <div className=''><h4 className="mb-1 firstrowtext py-3 text-left" style = {{fontSize:"14px"}}>Target <span className='font-semibold'>{props?.TARGET}%</span> 
                        {
                            props?.METRIC_NAME == "Refferal Rate" || props?.METRIC_NAME == "Referral Rate"? 
                            props?.TARGET_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            props?.METRIC_NAME == "Suspension Rate"? 
                            props?.TARGET_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            props?.METRIC_NAME == "Chronic Absence Rate"? 
                            props?.TARGET_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            props?.METRIC_NAME == "Expulsion Rate"? 
                            props?.TARGET_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            props?.TARGET_VARIANCE>=0 ? <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>           
                        }
                        </h4></div>
                        <div className=''><h4 className="mb-1 firstrowtext py-3 text-left" style = {{fontSize:"14px"}}>Last Year <span className='font-semibold'>{props?.LAST_PERIOD.toFixed(1)}%</span>
                        {
                            props?.METRIC_NAME == "Refferal Rate" || props?.METRIC_NAME == "Referral Rate"?  
                            props?.LAST_PERIOD_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            props?.METRIC_NAME == "Suspension Rate"?  
                            props?.LAST_PERIOD_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            props?.METRIC_NAME == "Chronic Absence Rate"?  
                            props?.LAST_PERIOD_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            props?.METRIC_NAME == "Expulsion Rate"?  
                            props?.LAST_PERIOD_VARIANCE>=0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            props?.LAST_PERIOD_VARIANCE>=0 ? <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>:
                            <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style = {{fontSize:"14px",position:"relative",left:"5px"}}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>       
                        }    
                        </h4></div>  
                    </div>
                </div>
                </div>                   
           </div>:
           <div className=" text-center strength-vertical-slider">
           <div className="w-full flex justify-between items-center px-4">
               <h4 className="mb-1 firstrowtext py-3 text-left">{props?.METRIC_NAME}</h4>
               <h4 className="mb-1 firstrowtext py-3 text-right">{props?.ACTUAL.toFixed(1)}%</h4>
           </div>       
           </div>
            }
            </div>
          </>
        );
    };
    return (
        <div className="body">
            <TopNav pagename = "Strength & Weakness" pagenamedescription = "Key Facts about the District" setUserData={props.setUserData}/>
            <div className="flex justify-between items-center mx-3 my-2 strength">
                <div className="wrapper-card2 w-full mx-2">
                    <div className="streweak-vertical-carousel">
                        <div className="">
                            <div className='flex justify-between items-center bg-[#1b1422] h-[40px] pr-2'>
                                <div className="px-3 flex items-center justify-around">
                                    <img src={thumb} style={{ height: 18, width: 18 }} /><span className="xl font-[400] mx-2">Strengths</span>
                                </div>
                            </div>
                        </div>
                        <Carousel value={Strength_Tile_Reportdata} numVisible={5} numScroll={1} orientation="vertical" verticalViewPortHeight="250px"
                            itemTemplate={strength}
                             />
                        </div>
                    </div>
                <div className="wrapper-card2 w-full mx-2">
                    <div className="streweak-vertical-carousel">
                        <div className="">
                        <div className='flex justify-between items-center bg-[#1b1422] h-[40px] pr-2'>
                        <div className="px-3 flex items-center justify-around">
                        <img src={improvementimg} style={{ height: 18, width: 18 }} /><span className="xl font-[400] mx-2">Improvement Shown</span>
                        </div>
                        </div>
                        </div>
                        <Carousel value={Improvement_Tile_Reportdata} numVisible={7} numScroll={1} orientation="vertical" verticalViewPortHeight="250px"
                            itemTemplate={improvement} />
                    </div>
                </div>
                <div className="wrapper-card2 w-full mx-2">
                    <div className="streweak-vertical-carousel">
                        <div className="">
                        <div className='flex justify-between items-center bg-[#1b1422] h-[40px] pr-2'>
                        <div className="px-3 flex items-center justify-around">
                        <img src={thumbdown} style={{ height: 18, width: 18 }} /><span className="xl font-[400] mx-2">Weaknesses</span>
                        </div>
                        </div>
                        </div>
                        <Carousel value={Weakness_Tile_Reportdata} numVisible={6} numScroll={1} orientation="vertical" verticalViewPortHeight="250px"
                            itemTemplate={weakness} />
                    </div>
                </div>
            </div>
            <div className="card wrapper-card text-white mx-5 my-3">
                <Card title="">
                    <div className="flex justify-between studenteoc-civi">
                        <div className="title-text flex items-center">{selectedmetricname}</div>
                        <div className="flex justify-center">
                            <div className="mx-1">
                                <label htmlFor="dd-city" className="labeltext">Dimension 1</label>
                                <Dropdown value={selectedoptions} onChange={(e) => setSelectedoptions(e.value)} options={options} optionLabel="name"
                                    placeholder="School Type" className="w-full md:w-14rem" />
                            </div>
                            <div className="mx-1">
                                <label htmlFor="dd-city" className="labeltext">Dimension 2</label>
                                <Dropdown value={selectedoptions1} onChange={(e) => setSelectedoptions1(e.value)} options={options1} optionLabel="name"
                                    placeholder="Ethinicity" className="w-full md:w-14rem" />
                            </div>
                            <div className="mx-1">
                                <label htmlFor="dd-city" className="labeltext">Dimension 3</label>
                                <Dropdown value={selectedoptions2} onChange={(e) => setSelectedoptions2(e.value)} options={options2} optionLabel="name"
                                    placeholder="Grade Level" className="w-full md:w-14rem" />
                            </div>
                            <div className="mx-1">
                                <label htmlFor="dd-city" className="labeltext">Dimension 4</label>
                                <Dropdown value={selectedoptions3} onChange={(e) => setSelectedoptions3(e.value)} options={options3} optionLabel="name"
                                    placeholder="Gender" className="w-full md:w-14rem" />
                            </div>
                            {/* <div className='text-[#fff] text-xs w-1'><Link><i class="pi pi-ellipsis-v"></i></Link></div> */}
                        </div>
                    </div>
                    <p className="m-0 text-white flex items-center justify-start">
                    {/* <ReactEcharts option={drilldown} style={{ width: '70%',height:'500%' }} /> */}
                    <TreeMapEcharts
                    handleTreeOnClick={handleTreeOnClick} 
                     data={STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata} 
                    nestedFields={nestedFields} valueFields={valueFields} 
                    // loading={ STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTloading }
                    loading={ false }
                    //  shareFields={shareFields}
                    />
                    </p>
                </Card>
            </div>
            <div className="py-4 flex justify-end">
                    <img src={k12darklogo} height="50px" width="130px" alt="logo" />
                </div>
        </div>
    )
}